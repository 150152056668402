.container-fluid {
  margin-top: 20px;
}

.sidebar {
  background-color: darkslategray;
  color: white;
}

.sidebar a {
  font-weight: bold;
}

.title {
  font-weight: bold;
}

.contact {
  list-style-type: none;
  padding-left: 15px;
}
